import { computed, defineComponent, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { createCipheriv, createDecipheriv, getCiphers } from "crypto";
// import { useRouter } from "vue-router";
import ScrollContainer from "@/components/ui/scrollContainer.vue";
import common from "../../utils/common";
import axios from "axios";
const CryptoJS = require("crypto-js"); //引用AES源码js

export default defineComponent({
    name: "chat",
    props: {},
    components: { ScrollContainer },
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();

        // const imServer = common.isProduction
        //     ? "https://im.blockwar.io"
        //     : "http://chat.wargamedev.com";
        const imServer = common.userApi.requestUrl;
        let key = "";
        //解密方法
        function decryptFromBase64(text) {
            if (!text) return;
            try {
                let keyBuffer = Buffer.from(key, "hex");
                let viStr = key.substring(0, 32);
                let viBuffer = Buffer.from(viStr, "hex");
                let decipher = createDecipheriv("aes-256-cbc", keyBuffer, viBuffer);
                decipher.setAutoPadding(true);

                let decrapted = decipher.update(text, "base64", "utf8");
                let finalHex = decipher.final("utf8");
                return decrapted + finalHex;
            } catch (e) {
                console.error(e)
                return '{"msg":"ERROR: unable to decrypt data!"}';
            }
        }

        //读取消息内容
        const formatMsgContentTxt = (text, contentType = 1) => {
            const str = decryptFromBase64(text);
            const strJson = JSON.parse(str);
            return strJson.msg;
        };
        const formatMsgContentImg = (text, contentType = 1) => {
            const str = decryptFromBase64(text);
            const strJson = JSON.parse(str); console.log('--lb', strJson)
            return strJson.view;
        };
        // setTimeout(function () { console.log('--lb',decryptFromBase64('gi7dbGfUAcQt3kuwcmkn9EAZYkcriGRdggKG/kAy5KGTR7T0OLxMniLBecs+1N22iQ/+ztPH3Rnp7q65JGiqQLdeegzOYgIVOzafqOeoIw8KcP5ZK7fL83CRd1k4BQeKc6nYJtA2p2D2V9flXRfj2Bsz+IAUBovw9rmKLD8NJGcIxiQN5LE0CLT/7+TVRxKyBFH6sLHBjGxo+u/XQ+crIkMg4opQmpnBUMuaEY21txoKb3gSVhz78aSjqbjc8ht9lCTrOjdVPCC6OYVxnLnDm+42CK4E6jNll/RFm+44K5TDOq8eZ3rDNR0/nYjyr/vUtGXj+gpCMBpQcE7rFzTJCjGdsSBP2fUsml4L2gFj01z6KWHdahM2GLcrRh0oVplhI0n/M6nAlfbkW0oJlU+uO4YEqfLu3TmjOjnvOYTmjSQ=')) }, 5000)

        //加密方法
        function encryptToBase64(text) {
            if (!text) return;
            let keyBuffer = Buffer.from(key, "hex");
            let viStr = key.substring(0, 32);
            let viBuffer = Buffer.from(viStr, "hex");
            let cipher = createCipheriv("aes-256-cbc", keyBuffer, viBuffer);
            cipher.setAutoPadding(true);
            let output = cipher.update(text, "utf8", "base64");
            return output + cipher.final("base64");
        }

        //获取交谈对象信息
        let otherUid = common.getQueryVariable("touid");
        let otherUserInfo = ref(null);
        let myUid = computed(() => {
            return store.state.userInfo ? store.state.userInfo.uid : "";
        });
        //因为IM头像和页面的头像不一致，所以再拉一次
        let myUserInfo = ref(null);
        const getUserInfo = (otherORme = "me") => {
            console.log('--lb', "getUserInfo", otherORme);
            console.log('--lb', "otherUid", otherUid);
            if (!otherUid) {
                return;
            } else {
                const sendUid = otherORme == "other" ? otherUid : myUid.value;
                const sendUrl = imServer + "/im_api/users?type=1&ids=" + sendUid;
                const sendHeaders = common.buildHeaders("from");
                common.showLoading("getUserInfo");
                axios
                    .get(sendUrl, { headers: sendHeaders })
                    .then((r) => {
                        console.log('--lb', r);
                        if (r.data.code == 0) {
                            if (otherORme == "other") {
                                otherUserInfo.value = r.data.data[0];
                                key = r.data.data[0].secret;
                                receMsg();
                            } else {
                                myUserInfo.value = r.data.data[0];
                                getUserInfo("other");
                            }
                            console.log('--lb', r.data.data[0]);
                        } else {
                            console.error(r.data.msg);
                            toastr["error"](r.data.msg);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        toastr["error"](e);
                    })
                    .finally(() => {
                        common.hideLoading("getUserInfo");
                    });
            }
        };
        let msgTxt = ref("");

        //发送消息
        const sendMsg = () => {
            if (!msgTxt.value) {
                return;
            }
            const msgContent = JSON.stringify({
                msg: msgTxt.value,
            });
            console.log('--lb', "msgContent", msgContent);
            const sendUrl = imServer + "/im_api/priv_chat/" + otherUid;
            const sendHeaders = common.buildHeaders("form");
            const sendData = common.buildSendData({
                to_uid: otherUid,
                type: 1,
                content: encryptToBase64(msgContent),
            });
            console.log('--lb', "sendData", sendData);
            axios
                .post(sendUrl, sendData, { headers: sendHeaders })
                .then((r) => {
                    console.log('--lb', r);
                    if (r.data.code == 0) {
                        msgTxt.value = "";
                    } else {
                        console.error(r.data.msg);
                        toastr["error"](r.data.msg);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    toastr["error"](e);
                })
                .finally(() => { });
        };

        //消息设为已读
        let receST;
        const setMsgReadedStatus = () => {
            const sendUrl = imServer + "/im_api/priv_chat/readed/" + otherUid;
            const sendHeaders = common.buildHeaders("form");
            const sendData = common.buildSendData({});
            axios
                .patch(sendUrl, sendData, { headers: sendHeaders })
                .then()
                .catch()
                .finally(() => {
                });
        };

        onUnmounted(() => {
            clearTimeout(receST);
        });

        //拉取消息
        let msgList = ref([]);
        //监控登录情况
        watch(myUid, () => {
            if (myUid.value) {
                getUserInfo("me");
            }
        });
        let last_seqnum = null;
        const receMsg = () => {
            if (!myUserInfo.value) {
                return;
            }
            let sendUrl =
                imServer + "/im_api/priv_chat/messages?peer_uid=" + otherUid;
            if (last_seqnum) {
                sendUrl += "&last_seqnum=" + last_seqnum;
            }
            const sendHeaders = common.buildHeaders("form");
            axios
                .get(sendUrl, { headers: sendHeaders })
                .then((r) => {
                    // console.log('--lb',r);
                    if (r.data.code == 0) {
                        if (r.data.data.length > 0) {
                            msgList.value = msgList.value.concat(r.data.data);
                            const lastIndex = msgList.value.length - 1;
                            if (msgList.value[lastIndex] && msgList.value[lastIndex].seqnum) {
                                last_seqnum = msgList.value[lastIndex].seqnum;
                            }
                            //             # 设置私聊消息为已读
                            setMsgReadedStatus();
                        }
                    } else {
                        console.error(r.data.msg);
                        toastr["error"](r.data.msg);
                    }
                })
                .catch((e) => {
                    console.error(e);
                    toastr["error"](e);
                })
                .finally(() => {//循环拉取消息
                    clearTimeout(receST);
                    receST = setTimeout(function () {
                        receMsg();
                    }, 1000);
                });
        };
        if (myUid.value) {
            getUserInfo("me");
        } else {
            common.showLogin();
        }

        const formatDateTime = (t) => {
            return common.formatDateTime(t);
        };

        return {
            imServer,
            msgTxt,
            sendMsg,
            otherUserInfo,
            myUserInfo,
            msgList,
            formatDateTime,
            decryptFromBase64,
            encryptToBase64,
            formatMsgContentTxt, formatMsgContentImg,
        };
    },
});