<template>
  <div class="scrollbar-box" :class="{ hide: winWidth < 500 }" :style="{ height: height }">
    <div class="scrollbar-y">
      <div ref="scrollRef" class="scroll-wrap" :style="{ height: height, paddingRight: scrollWidth*2 + 'px' }"
        @scroll="onMosewheel">
        <slot></slot>
      </div>
      <div ref="barRef" class="scrollbar-track" :style="{
        backgroundColor: heightPre == 1 ? 'rgba(0,0,0,0)' : slotColor,width: scrollWidth + 'px',
      }">
        <div :style="{
          height: barHeight + 'px',
          width: scrollWidth + 'px',
          transform: 'translateY(' + translateY + 'px)',
          backgroundColor: heightPre == 1 ? 'rgba(0,0,0,0)' : scrollColor,
          cursor: heightPre == 1 ? 'default' : 'pointer',
        }" class="scrollbar-thumb" @mousedown.stop.prevent="moveStart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  toRefs,
  onMounted,
  nextTick,
  reactive,
  onUnmounted,
} from "vue";
export default defineComponent({
  name: "scrollContainer",
  props: {
    height: {
      //:height="'200px'"
      type: String,
      default: "100%",
    },
    slotColor: {
      //滑塊槽顏色 :slotColor="'red'"
      type: String,
      default: "rgba(0,0,0,0)",
    },
    scrollColor: {
      //滑塊顏色  :scrollColor="'red'"
      type: String,
      default: "#999",
    },
    scrollWidth: {
      //滑塊寬度 :scrollWidth="7"
      type: Number,
      default: 12,
    },
    keepbottom: {
      //滑塊保持在底部
      type: Boolean,
      default: false,
    },
  },
  emits: ['arrive'],
  setup(props, ctx) {
    const data = reactive({
      scrollRef: null, // 內容盒子
      barRef: null, // 滾動條軌道
      translateY: 0, // 滾動塊平移的距離
      heightPre: 0, // 可視高度和內容高度比
      barHeight: 0, // 滑塊高度
      winWidth: document.body.clientWidth, //初始化瀏覽器頁面寬度
    });
    let time = null; // 定時器
    let isMove = false; // 判斷鼠標是否點擊滑塊（為鬆開）
    let moveClientY = 0; // 鼠標點擊滑塊時，相對滑塊的位置
    let trackHeight = 0; // 滾動條軌道高度
    let wrapHeight = 0; // 容器高度（可視高度）
    let wrapContentHeight = 0; // 內容高度（可滾動內容的高度）

    //讓滾動條保持在底部
    var canToBottom = true;
    const keepScrollBottom = function () {
      if (canToBottom) {
        const scrollObj = data.scrollRef;
        scrollObj.scrollTop = scrollObj.scrollHeight;
      }
    };
    // 監聽頁面尺寸改變計算滾動條
    let monitorWindow = function () {
      let time; //定時器，防抖，窗口持續變化，延遲更新滾動條
      window.addEventListener("resize", () => {
        data.winWidth = document.body.clientWidth; //頁面改變監聽寬度控制移動端隱藏滾動條
        clearTimeout(time);
        time = setTimeout(() => {
          //頁面寬度變化繼續監聽，如果小於500就關閉自定義滾動條
          // console.log('--lb',"瀏覽器窗口變化更新滾動條");
          initScrollListner();
        }, 500);
      });
    };

    //監聽內容元素尺寸變化
    let monitorScrollBar = function () {
      var monitorUl = data.scrollRef.children[0];
      // var monitorDiv= document;    // 監聽document
      let MutationObserver =
        window.MutationObserver ||
        window.WebKitMutationObserver ||
        window.MozMutationObserver;
      let observer = new MutationObserver(function (mutations) {
        // console.log('--lb',"內容元素變化更新滾動條");
        initScrollListner();
      });

      // childList  觀察子節點變動
      // 監聽子節點增加或者內容撐起的尺寸
      observer.observe(monitorUl, {
        attributes: true,
        childList: true,
        // subtree: true,    //監聽子元素內容變化
      });
      // 監聽document
      // observer.observe(monitorDiv, {
      //   childList:	true,
      //   subtree:	true	,
      // });
    };

    // 初始化延遲監聽滾動條
    let calculationLength = function () {
      // console.log('--lb',"初始化頁面更新滾動條");
      // 直接執行initScrollListner函數，獲取滾動條長度部準確
      // 因為頁面渲染有延遲，獲取dom元素需要延遲
      // 每間隔10毫秒更新滑塊長度
      time = setInterval(() => {
        // 計算滾動條高度
        initScrollListner();
      }, 50);
      // 間隔500毫秒清除定時器，滑塊縮短會有動畫效果，時間可延長沒有影響
      setTimeout(() => {
        window.clearInterval(time);
        time = null;
      }, 2000);
    };
    // 計算滾動條高度
    const initScrollListner = function () {
      let scroll = data.scrollRef;
      let bar = data.barRef;
      //判斷是否要讓滾動條保持在底部
      if (props.keepbottom) {
        keepScrollBottom();
      }
      // scroll有時候拿不到元素，要判斷一下
      if (scroll) {
        wrapContentHeight = scroll.scrollHeight;
        wrapHeight = scroll.clientHeight;
        trackHeight = bar.clientHeight;
        // console.log('--lb',wrapContentHeight ,wrapHeight);
        // 容器高度 / 內容高度   100 150
        data.heightPre = wrapHeight / wrapContentHeight;
        // 滑動塊的高度 根據 容器和內容的比  乘以 滾動軌道 計算出 滑動塊的高度
        data.barHeight = data.heightPre * trackHeight;
      }
    };
    // 到達頂部或者底部通知父級元素
    const arrive = function name(tb) {
      console.log('--lb', tb);
      ctx.emit("arrive", tb);
    };
    // 內容滾動時，計算滑塊移動的距離
    const onMosewheel = function (e) {
      canToBottom = false;
      // console.log('--lb',"bottom", canToBottom);
      // scrollTop頁面頂部滾出的高度
      // offsetHeight頁面可視區域高度
      // scrollHeight頁面正文全文高度
      // data.translateY滾動塊平移的距離
      data.translateY = e.target.scrollTop * data.heightPre;
      if (data.translateY == 0) {
        // 到達頂部
        arrive("top");
        // console.log('--lb',"top");
      } else if (
        Math.ceil(e.target.scrollTop) + Math.ceil(e.target.offsetHeight) >=
        e.target.scrollHeight - 10
      ) {
        data.scrollRef = e.target;
        canToBottom = true;
        // 滾出高度 + 可視區域高度 == 內容高度
        arrive("bottom");
        // console.log('--lb',"bottom");
      }
    };
    // 鼠標點擊滑塊時
    const moveStart = function (e) {
      isMove = true;
      // clientY：當鼠標事件發生時，鼠標相對於瀏覽器（這裡說的是瀏覽器的有效區域）y軸的位置
      // data.translateY 滾動塊平移的距離
      // moveClientY 鼠標點擊滑塊時，相對滑塊的位置
      moveClientY = e.clientY - data.translateY;
      moveTo(); //移動時
      moveEnd(); //鼠標鬆開時
    };
    // 鼠標移動，改變thumb的位置以及容器scrollTop的位置
    const moveTo = function () {
      document.onmousemove = (e) => {
        // 移動時候判斷是不是鬆開，鬆開就不在執行滑塊移動操作
        if (isMove) {
          // 移動滑塊時，判斷時候到達頂部或者底部
          if (e.clientY - moveClientY > trackHeight - data.barHeight) {
            // 滑塊到達  底部  就不在改變滑塊translateY值
            data.translateY = trackHeight - data.barHeight;
          } else if (e.clientY - moveClientY < 0) {
            // 滑塊到達  頂部  就不在改變滑塊translateY值
            data.translateY = 0;
          } else {
            //改變滑塊位置
            data.translateY = e.clientY - moveClientY;
          }
          // 計算出內容盒子滾出頂部的距離
          data.scrollRef.scrollTop = data.translateY / data.heightPre;
        }
      };
    };
    // 鼠標從滑塊鬆開時，不在監聽滑塊移動操作
    const moveEnd = function () {
      document.onmouseup = (e) => {
        if (isMove) {
          isMove = false;
        }
      };
    };

    // 頁面掛載後計算滾動條
    onMounted(() => {
      monitorWindow(); //監聽窗口尺寸
      monitorScrollBar(); //監聽內容元素尺寸
      nextTick(() => {
        //dom渲染後
        calculationLength(); //初始化延遲更新滾動條
      });
    });
    // 頁面卸載清除定時器
    onUnmounted(() => {
      monitorWindow = null;
      monitorScrollBar = null;
      calculationLength = null;
      window.clearInterval(time);
      time = null;
    });

    return {
      ...toRefs(data),
      onMosewheel,
      moveStart,
    };
  },
});
</script>
<style lang="scss" scoped>
.scrollbar-box {
  height: 100%;
  overflow: hidden;

  &:hover {
    .scrollbar-track {
      opacity: 1;

      .scrollbar-thumb {
        opacity: 1;
      }
    }
  }
}

.scrollbar-y {
  position: relative;
  height: 100%;
  overflow: hidden;

  .scroll-wrap {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .scrollbar-track {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.08rem;
    z-index: 20;
    opacity: 0;

    .scrollbar-thumb {
      margin: 0 auto;
      border-radius: 0.06rem;
      opacity: 0;
    }
  }
}
</style>