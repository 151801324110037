<template>
  <div class="chat">
    <div class="container">
      <div class="left">
        <div class="top">CHAT LIST</div>

        <scrollContainer
          :height="'6.54rem'"
          :slotColor="'rgba(0, 0, 0, 0)'"
          :scrollColor="'#DDDEE0'"
        >
          <ul>
            <li class="active">
              <div class="avatar">
                <img
                  :src="otherUserInfo ? otherUserInfo.head_url : ''"
                  alt=""
                />
              </div>
              <div class="summary">
                <div class="name">
                  {{ otherUserInfo ? otherUserInfo.nick : "" }}
                </div>
                <!-- <div class="msg"></div> -->
              </div>
              <!-- <div class="date"></div> -->
            </li>
          </ul>
        </scrollContainer>
      </div>
      <div class="right">
        <div class="top">{{ myUserInfo ? myUserInfo.nick : "" }}</div>
        <div class="msgbox">
          <scrollContainer
            :height="'5.66rem'"
            :slotColor="'rgba(0, 0, 0, 0)'"
            :scrollColor="'#DDDEE0'"
            :scrollWidth="10"
            :keepbottom="true"
          >
            <div class="scorll_box">
              <div
                v-for="(item, index) in msgList"
                :key="index"
                :data-time="formatDateTime(item.send_time)"
                :data-type="formatDateTime(item.type)"
              >
                <div
                  :class="{
                    msg: true,
                    me: item.from_uinfo.uid == myUserInfo.uid ? true : false,
                  }"
                >
                  <div class="avatar">
                    <img :src="item.from_uinfo.head_url" alt="" />
                  </div>
                  <div class="msgtxt" v-if="item.type == 1">
                    {{ formatMsgContentTxt(item.content) }}
                  </div>
                  <div class="msgtxt" v-else-if="item.type == 2">
                    <img
                      v-if="formatMsgContentImg(item.content).url"
                      :src="
                        imServer + '' + formatMsgContentImg(item.content).url
                      "
                      :style="{
                        width: formatMsgContentImg(item.content).width + 'px',
                        height: formatMsgContentImg(item.content).height + 'px',
                      }"
                      alt=""
                    />
                    <span v-else
                      >Received a picture, please check it in HashTalk!</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </scrollContainer>
        </div>
        <div class="input">
          <input
            type="text"
            placeholder="Write a message..."
            v-model="msgTxt"
            @keydown.enter="sendMsg"
          /><span class="send" @click="sendMsg"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped src="./chat.scss"></style>
<style lang="scss">
.right {
  .msgbox {
    .scrollbar-box {
      .scrollbar-track {
        opacity: 1 !important;
      }
    }
  }
}

@media screen and (max-width: 1059px){
  .router_chat{
    .chat {
      .container{
        display: block;
        width: 100%;
        top: 1.2rem;
        left: 0;
        margin: 0;
        border: 0;
        border-radius: 0;
        height: calc(100vh - 1.2rem);
        .left{
            display: none;
            background-color: #DBDBE6;;
        }
        .right{
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 100%;
          .top{
            height: .96rem;
            background-color: #1B203F;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .32rem;
            color: #fff;
            flex-shrink: 0;
            flex-grow: 0;
          }
          .msgbox{
            background-color: #DBDBE6;
            height: calc(100% - .96rem - 1.84rem);
            .scrollbar-box{
              height: 100% !important;
              .scroll-wrap{
                height: 100% !important;
              }
              .scrollbar-track{
                .scrollbar-thumb{
                  background-color: rgba(255,255,255,.5) !important;
                }
              }
            }
            .scorll_box{
              .msg{
                margin-bottom: .4rem;
                .avatar{
                  width: .8rem;
                  height: .8rem;
                }
                .msgtxt{
                  font-size: .32rem;
                  line-height: 1.5;
                }
              }
            }
          }
          .input{
            padding-top: .18rem;
            height: 1.84rem;
            box-sizing: border-box;
            flex-shrink: 0;
            flex-grow: 0;
            align-items:flex-start;
            input{
              background-color: #F2F2F7;
              height: .8rem;
              width: 6.22rem;
              padding: 0 0.1rem;
              box-sizing: border-box;
              font-size: .32rem;
            }
            .send{
              width: .8rem;
              height: .8rem;
            }
          }
        }
      }
    }
  }
}
</style>
<script src="./chat.js"></script>
